import styled from "styled-components";

export const HeaderWrapper = styled.div`
  #header {
    background: #fff;
    box-shadow: 0px 1px 9.8px 0px rgba(0, 0, 0, 0.1);
    height: 65px;
    padding: 10px 20px;
    .filterData {
      gap: 30px;
      .menu {
        width: 30px;
        display: block;
        cursor: pointer;
        position: relative;
        top: 5px;
        i {
          width: 100%;
          height: 3px;
          margin-bottom: 6px;
          display: block;
          background: #164781;
          border-radius: 100px;
          transition: all ease-out 0.5s;
          &:first-child,
          &:last-child {
            width: 75%;
          }
        }
        i:nth-child(2) {
          display: none !important;
        }
        i:first-child {
          transform: rotate(45deg);
        }
        i:last-child {
          transform: rotate(-45deg);
          position: relative;
          top: -8px;
          left: 0px;
        }
      }

      .textBoxWrapper {
        position: relative;
        * {
          box-sizing: border-box;
        }
        input {
          color: rgba(113, 113, 150, 0.6);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px;
          border-radius: 8px;
          border: 1px solid rgba(139, 169, 215, 0.3);
          width: 400px;
          height: 40px;
          padding: 10px;
          padding-right: 30px;

          &::placeholder {
            color: rgba(113, 113, 150, 0.6);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
          }
          &:focus-visible {
            outline: none;
          }
        }
      }
      svg {
        position: absolute;
        right: 10px;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .rightPart {
      gap: 40px;
    }
  }
`;
