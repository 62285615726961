import { atom, useAtom } from "jotai";

const toggleMenu = atom<boolean>(true);

const tokenAtom = atom<string | undefined>("");

const userDetailsAtom = atom<string>("");

const loadingAtom = atom<boolean>(true);

function useToggleMenu() {
  return useAtom(toggleMenu);
}

function useToken() {
  return useAtom(tokenAtom);
}

function useUserDetails() {
  return useAtom(userDetailsAtom);
}

function useLoading() {
  return useAtom(loadingAtom);
}
export { useToggleMenu, useToken, useUserDetails, useLoading };
