const loadSvg = async (name) => {
  try {
    const Svg = await import(`../assets/icons/${name}.svg`);
    return Svg.default;
  } catch (error) {
    console.error(`Error loading SVG: ${name}`, error);
    return null;
  }
};

export default loadSvg;
