import React from "react";

const MenuLeft = () => {
  return (
    <svg className="left" width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.59001 28C0.210718 28.0094 0 28 0 28H0.59001C3.87434 27.9189 19.7983 26.4334 21 5.30215V0C21.0979 1.89224 21.0936 3.65675 21 5.30215V28H0.59001Z" fill="#EAEDF7" />
    </svg>
  );
};

export default MenuLeft;
