import React from "react";
import AppRouter from "./layout/AppRouter";
import { GlobalStyles } from "../assets/css/global";

function App() {
  return (
    <>
      <AppRouter />
      <GlobalStyles />
    </>
  );
}

export default App;
