import React from "react";

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M17.8395 16.9605L14.1641 13.2852C15.0489 12.1072 15.5266 10.6733 15.525 9.2C15.525 5.43672 12.4633 2.375 8.7 2.375C4.93672 2.375 1.875 5.43672 1.875 9.2C1.875 12.9633 4.93672 16.025 8.7 16.025C10.1733 16.0266 11.6072 15.5489 12.7852 14.6641L16.4605 18.3395C16.6466 18.5058 16.8893 18.5945 17.1387 18.5876C17.3881 18.5806 17.6255 18.4784 17.8019 18.3019C17.9784 18.1255 18.0806 17.8881 18.0876 17.6387C18.0945 17.3893 18.0058 17.1466 17.8395 16.9605ZM3.825 9.2C3.825 8.23582 4.11091 7.29328 4.64659 6.4916C5.18226 5.68991 5.94363 5.06506 6.83442 4.69609C7.72521 4.32711 8.70541 4.23057 9.65107 4.41867C10.5967 4.60677 11.4654 5.07107 12.1471 5.75285C12.8289 6.43464 13.2932 7.30328 13.4813 8.24893C13.6694 9.19459 13.5729 10.1748 13.2039 11.0656C12.8349 11.9564 12.2101 12.7177 11.4084 13.2534C10.6067 13.7891 9.66418 14.075 8.7 14.075C7.40755 14.0734 6.16847 13.5593 5.25457 12.6454C4.34066 11.7315 3.82655 10.4925 3.825 9.2Z"
        fill="#717196"
      />
    </svg>
  );
};

export default SearchIcon;
