import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a{
  text-decoration: none;
}
.cursor{
  cursor: pointer;

}
* {
          box-sizing: border-box;
        }

/* Grid  */
  .flex{
    display:flex;
  }
  .flex-justify{
    justify-content: space-between;
    display: flex;
  }
  .flex-center{
    display: flex;
    align-items:center;
    justify-content: center;
  }
  .flex-item-center{
    display: flex;
    align-items:center;
  }
  .gap10{
    gap: 10px;
  }
/* Global css */
body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;


  .btnWrapper{
  display: flex;
}
  }
  // Gobal Button
.btnWrapper{
  display: flex;
  gap: 10px;
  li{
    span{
      display: block;
      border-radius: 10px;
border: 1px solid  #98ABC5;
color:  #717196;
text-align: center;
font-size: 16px;
font-weight: 400;
line-height: 20px; 
padding:9px 14px;
cursor: pointer;
transition: all ease 0.5s;
&:hover,&.active:hover{
  border-color: #1483DE;
  background: #fff;
  color: #717196;
}
&.active{
  background: #1483DE;
  color: #fff;
  border-color:#1483DE ;
}
    }
  }
}


/* form elements */

textarea {
        border-radius: 10px;
        border: 1px solid rgba(139, 169, 215, 0.4);
        background: #fff;
        padding: 8px 12px;
        resize: none;
        width: 100%;
        color:  #A2B5CD;
font-size: 13px;
font-weight: 400;
line-height: 16px; 
        &::placeholder{
          color:  #A2B5CD;
font-size: 13px;
font-weight: 400;
opacity: 1;
        }
        &:focus-visible{
          outline: none;
        }
      }

  // menu collepse

   .activeMenu {
  .LogoPart {
    padding: 10px 19px;
  }
  .navigation nav ul li{
span,.arrowIcon,ul{
  display: none;
}
  }
  aside {
  width: 80px;
  }
  #header .filterData .menu{
  i:nth-child(2){
    display: block !important;

  }
  i:first-child{
    transform: rotate(0);
  }
  i:last-child{
    transform: rotate(0);
    position: relative;
    top: 0;
    left: 0px;
  }
}
  
}

`;
