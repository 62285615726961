import { getBaseEndpoint } from "@bjsdistribution/tms-component-library";

function useLogin() {
  const perform = async () => {
    const endpoint = `${getBaseEndpoint()}/begin_login/`;
    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error("response is not ok");
      }
      const json = await response.json();
      if (json.status === "success") {
        return json.data;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err, "error");
      return [];
    }
  };
  return perform;
}

export default useLogin;
