import LazySvg from "../../../utils/LazySvg";
import { StyledAside } from "./AsideStyle";
import { menuItems } from "../../../utils/menu";
import { NavLink, useLocation } from "react-router-dom";
import MenuLeft from "../../../assets/icons/MenuLeft";
import MenuRight from "../../../assets/icons/MenuRight";
import { useToggleMenu } from "../../common/store";
const Aside = () => {
  const [toogleMenu, setToggleStatus] = useToggleMenu();

  const location = useLocation();
  console.log(location.pathname);

  return (
    <>
      <StyledAside>
        <div className="LogoPart">
          {location.pathname === "/supplier-onboarding" ? (
            <a href="#">{toogleMenu ? <LazySvg name="logo-small" /> : <LazySvg name="supplier-logo" />}</a>
          ) : (
            <a href="#">{toogleMenu ? <LazySvg name="logo-small" /> : <LazySvg name="logo" />}</a>
          )}
        </div>
        <div className="navigation">
          <nav>
            <ul>
              {menuItems.map((item, id) => (
                <li key={id} className={location.pathname.startsWith(item.url) ? "active" : ""}>
                  <i className="arrowIcon">
                    <LazySvg name="arrow" />
                  </i>
                  <NavLink to={item.url} className={({ isActive }) => (isActive ? "active" : "")}>
                    <div className="flex flex-item-center gap10">
                      <i className="iconWrapper flex-center">
                        <LazySvg name={item.iconName} />
                      </i>
                      <span>{item.name}</span>
                    </div>
                  </NavLink>
                  <ul>
                    {item?.subMenu?.map((suMenu, index) => (
                      <li key={index}>
                        <NavLink end to={suMenu.url} className={({ isActive }) => (isActive ? "active" : "")}>
                          <div className="flex flex-item-center gap10">
                            <span>{suMenu.name}</span>
                          </div>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                  <div className="arrow">
                    <MenuLeft />
                    <MenuRight />
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        {/* <LazySvg name="vehicle" /> */}
      </StyledAside>
    </>
  );
};

export default Aside;
