export const menuItems = [
  { id: 1, name: "supplier onboarding", iconName: "home", url: "/supplier-onboarding" },
  {
    id: 2,
    name: "onboarding",
    iconName: "onboarding",
    url: "/onboarding",
    subMenu: [
      {
        name: "New Request",
        url: "/onboarding",
      },
      {
        name: "requested",
        url: "/onboarding/requested",
      },
    ],
  },

  {
    id: "M3",
    name: "Vehicles",
    iconName: "vehicles",
    url: "/add-vehicle",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/add-vehicle",
      },
    ],
  },

  {
    id: "M4",
    name: "Drivers",
    iconName: "drivers",
    url: "/drivers",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/abc",
      },
    ],
  },

  {
    id: "M5",
    name: "Clients",
    iconName: "clients",
    url: "/clients",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/abc",
      },
    ],
  },

  {
    id: "M6",
    name: "Finance",
    iconName: "finance",
    url: "/finance",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/abc",
      },
    ],
  },

  {
    id: "M7",
    name: "Maintenance",
    iconName: "maintenance",
    url: "/maintenance",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/abc",
      },
    ],
  },

  {
    id: "M8",
    name: "Assets",
    iconName: "assets",
    url: "/assets",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/abc",
      },
    ],
  },
];
