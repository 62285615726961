import React from "react";

const MenuRight = () => {
  return (
    <svg className="right" width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.59001 0.00415039C0.210718 -0.00521469 0 0.00415039 0 0.00415039H0.59001C3.87434 0.0852451 19.7983 1.57075 21 22.702V28.0042C21.0979 26.1119 21.0936 24.3474 21 22.702V0.00415039H0.59001Z"
        fill="#EAEDF7"
      />
    </svg>
  );
};

export default MenuRight;
