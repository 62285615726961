import React from "react";
import { HeaderWrapper } from "./HeaderStyle";
import SearchIcon from "../../../assets/icons/SearchIcon";
import Notifications from "./Notifications";
import SuperAdmin from "./SuperAdmin";
import Setting from "./Setting";
import { useToggleMenu } from "../../common/store";

const Header = () => {
  const [toggleStatus, setToggleStatus] = useToggleMenu();
  const toggleMenu = () => {
    setToggleStatus(!toggleStatus);
  };
  return (
    <HeaderWrapper>
      <header id="header" className="flex-justify">
        <div className="filterData flex-item-center">
          <span className="menu" onClick={toggleMenu}>
            <i></i>
            <i></i>
            <i></i>
          </span>
          <div className="textBoxWrapper">
            <input type="text" placeholder="Search for anything..."></input>
            <SearchIcon />
          </div>
        </div>
        <div className="rightPart flex-item-center">
          <Notifications />
          <SuperAdmin />
          <Setting />
        </div>
      </header>
    </HeaderWrapper>
  );
};

export default Header;
